import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Navigation } from '../components/Navigation';
import { Error404 } from '../styles/icons';
import { HeroHeader404, Button } from '@ui';
import Layout from '../components/Layout';

const NotFoundPage = () => {
  const { formatMessage } = useIntl();

  return (
    <Layout>
      <Navigation />
      <HeroHeader404 tw="h-screen flex flex-col justify-center items-center">
        <Error404 tw="w-full h-auto mx-auto mb-10 pt-16 lg:(mb-0 pt-0) md:(max-width[400px] max-height[400px]) lg:(max-width[412px] max-height[412px]) xl:(max-width[512px] max-height[512px])" />
        <div tw="flex w-full justify-center pt-10">
          <a href="javascript:history.back()">
            <Button
              tw="mt-5 md:mt-0 w-full md:w-60 bg-primary text-white hover:opacity-80 px-5"
              shadow
              primary
              block
            >
              Back to Homepage
            </Button>
          </a>
        </div>
      </HeroHeader404>
    </Layout>
  );
};

export default NotFoundPage;
